<template>
  <div class="login">
    <el-steps :space="200" :active="active" finish-status="success" style="margin-left: 25%; margin-top: 5%">
      <el-step title="账号验证" />
      <el-step title="完善个人信息" />
      <el-step title="注册完成" />
    </el-steps>
    <el-row>
      <el-col>
        <p class="pTitle">用户注册</p>
      </el-col>

      <el-col>
        <el-form ref="formRef" style="margin-left: 25%; margin-top: 5%" :model="form" :rules="rules" label-width="10px"
          v-show="active === 0">
          <el-form-item label="" prop="phone">
            <el-input placeholder="请输入手机号" class="w-50" v-model="form.phone" size="large" />
          </el-form-item>
          <el-form-item label="" prop="verCode">
            <el-row>
              <el-col :span="14">
                <el-input v-model="form.verCode" size="large" style="width: 1.1979rem" placeholder="请输入验证码"
                  :prefix-icon="Picture" />
              </el-col>
              <el-col :span="10">
                <!-- <img
                  style="height: 40px; width: 0.6771rem; margin-left: 14px"
                  @click="getVer()"
                  :src="'data:image/jpg;base64,' + verData.img"
                /> -->
                <el-button style="height: 0.2083rem; width: 0.6771rem; margin-left: 0.0938rem;font-size: 12px;"
                  type="primary" @click="getVerCode" :disabled="state.getVerCodeStatus">{{
                    state.getVerCodeStatus
                    ? state.getVerCodeNum + "秒后可重新获取"
                    : "获取验证码"
                  }}</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>

        <el-form ref="formRef1" style="margin-left: 25%; margin-top: 5%" :model="form1" label-width="10px" :rules="rules1"
          v-show="active === 1">
          <el-form-item label="" prop="userName">
            <el-input style="width: 60%" placeholder="请输入证件上的真实姓名" v-model="form1.userName" size="large" />
          </el-form-item>
          <el-form-item label="" prop="idCardType">
            <el-select v-model="form1.idCardType" style="width: 60%" placeholder="选择证件类型" size="large">
              <el-option :value="0" label="居民身份证" />
              <el-option :value="1" label="港澳通行证" />
              <el-option :value="2" label="护照" />
              <el-option :value="3" label="临时身份证" />
              <el-option :value="4" label="港澳同胞回乡证" />
              <el-option :value="5" label="外国人居留证" />
              <el-option :value="6" label="外国人出入境证" />
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="idCardNum">
            <el-input style="width: 60%" placeholder="请填写证件号，注册完成后无法修改" v-model="form1.idCardNum" size="large" />
          </el-form-item>
          <p class="name">* 证件号注册完成无法修改</p>
          <el-form-item label="" style="margin-top: 10px" prop="userPwd">
            <el-input type="password" style="width: 60%" placeholder="请填写登陆密码" v-model="form1.userPwd" size="large" />
          </el-form-item>
          <el-form-item label="" style="margin-top: 10px" prop="userPwd1">
            <el-input type="password" style="width: 60%" placeholder="请填写登陆密码" v-model="form1.userPwd1" size="large" />
          </el-form-item>
        </el-form>
        <el-dialog v-model="state.dialogVisible" title="确认信息" width="40%">
          <div style="padding-left: 30%;font-size: 18px;font-weight: bold;">
            <div style="padding: 5px; color: #666666">
              姓名：<span>{{ form1.userName }}</span>
            </div>
            <div style="padding: 5px; color: #666666">
              证件类型：<span>{{ idCardTypeShow }}</span>
            </div>
            <div style="padding: 5px; color: #666666">
              证件号码：<span>{{ form1.idCardNum }}</span>
            </div>
          </div>
          <div style="color: #ee1010; text-align: center; padding-top: 40px">
            该信息填写错误将影响考试正常进行，且提交后无法修改，请仔细核对、确认!
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="state.dialogVisible = false">返回</el-button>
              <el-button :loading="state.loading" type="primary" @click="register()"> 提交 </el-button>
            </span>
          </template>
        </el-dialog>
        <div v-show="active === 2" style="text-align: center">
          <p style="font-size: 18px; color: #097afa; margin-top: 10%">
            注册完成
          </p>
          <div style="text-align: left; padding-left: 25%">
            <p class="p">1.您可使用注册时填写的手机号或证件号完成登录。</p>
            <p class="p">2.您的信息发生变更后，请及时登录平台更新个人信息。</p>
            <p class="p">
              3.请确保账号安全，忘记密码时可使用已绑定手机号或邮箱找回
            </p>
          </div>
        </div>
      </el-col>
      <el-col :span="13" :style="{
        marginTop: active === 2 ? '10%' : 0,
        marginLeft: active === 2 ? '25%' : '26.3%',
      }">
        <el-button class="w-50" type="primary" @click="next">{{
          text()
        }}</el-button>
        <p style="margin-top: 10px">
          <span>已有账号，<a class="resg" @click="toLogin">马上登录~</a></span>
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { defineProps, ref, reactive, watch, defineEmits, computed } from "vue";
import { post, get } from "@/api/api";
import { Picture } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { getCode, decrypt } from "@/utils/encrypt.js";
const emit = defineEmits(["close"]);
let router = useRouter();
const props = defineProps({
  flag: Boolean,
});
let idCardTypeShow = computed(() => {
  return state.cardTypeList[form1.idCardType];
});

watch(
  () => props.flag,
  (val) => {
    if (!val) {
      active.value = 0
      formRef.value.resetFields()
      formRef1.value.resetFields()
      // getVer();
    }
  }
);
let active = ref(0);

const text = () => {
  if (active.value === 0) return "下一步";
  if (active.value === 1) return "提交";
  if (active.value === 2) return "去登录";
};

let verData = reactive({});
const getVer = async () => {
  let res = await get("/verCode/get");
  Object.assign(verData, res.result);
};
let form = reactive({});
let form1 = reactive({});

const rules = reactive({
  phone: [
    {
      required: true,
      message: "手机号格式不正确",
      trigger: "blur",
      pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
    },
  ],
  verCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
});
const rules1 = reactive({
  userName: [
    { required: true, message: "请输入证件上的真实姓名", trigger: "blur" },
  ],
  verCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
  idCardType: [{ required: true, message: "选择证件类型", trigger: "blur" }],
  idCardNum: [
    { required: true, message: '', trigger: 'blur', pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/ }
  ],
  userPwd: [
    { required: true, message: "请填写登陆密码", trigger: "blur" },
    { min: 6, max: 18, message: "密码长度为 6 到 18", trigger: "blur" },
  ],
});

const formRef = ref();
const formRef1 = ref();
const next = async () => {
  if (active.value == 0) {
    formRef.value.validate(async (valid) => {
      if (valid) {
        let res = await post("/examApply/user/addFirst", {
          phone: form.phone,
          verCode: form.verCode,
          // verCodeId: verData.imgUUID,
        });
        if (res.code === 200) {
          active.value++;
        } else {
          ElMessage.error(res.message);
          getVer();
        }
      }
    });
    return;
  }

  if (active.value == 1) {
    if (form1.userPwd !== form1.userPwd1) {
      ElMessage.error("两次密码输入不一致！");
      return;
    }
    if (!form1.idCardType && form1.idCardType !== 0) {
      ElMessage.error("选择证件类型！");
      return;
    }
    formRef1.value.validate(async (valid) => {

      if (valid) {
        state.dialogVisible = true;
      }
    });
    return;
  }

  if (active.value == 2) {
    emit("callback", form.phone, form1.userPwd);
  }
};
const register = async () => {
  
  state.loading = true
  let userPwd = getCode(form1.userPwd);
  let res = await post("/examApply/user/addSecond", {
    userName: form1.userName,
    userPwd: userPwd,
    userType: 1,
    idCardNum: form1.idCardNum,
    idCardType: form1.idCardType,
    phone: form.phone,
    verCode: form.verCode,
    verCodeId: verData.imgUUID,
  });
  if (res.code === 200) {
    state.loading=true
    ElMessage.success('注册成功');
    active.value++;
    state.dialogVisible = false
  } else {
    state.loading=true
    ElMessage.error(res.message);
  }
};
const toLogin = () => {
  emit("callback", form.phone, form1.userPwd);
};
let state = reactive({
  resetPwdStatus: false,
  getVerCodeStatus: false,
  getVerCodeNum: 60,
  mysetInterval: null,
  dialogVisible: false,
  cardTypeList: [
    "居民身份证",
    "港澳通行证",
    "护照",
    "临时身份证",
    "港澳同胞回乡证",
    "外国人居留证",
    "外国人出入境证",
  ],
  loading: false,
});
// 注册获取手机验证码
const getVerCode = async () => {
  console.log();
  if (!form.phone) {
    ElMessage.error("请输入账号");
    return;
  }
  const res = await get("/examApply/user/sendPhoneCode", {
    phone: form.phone,
  });
  if (res.code === 200) {
    console.log(res);
    ElMessage.success(res.message);
    state.getVerCodeStatus = true;
    state.mysetInterval = setInterval(() => {
      state.getVerCodeNum--;
      if (state.getVerCodeNum <= 0) {
        state.getVerCodeStatus = false;
        state.getVerCodeNum = 60;
        clearInterval(state.mysetInterval);
      }
    }, 1000);
  } else {
    ElMessage.error(res.message || "返回数据错误");
  }

};

</script>

<style lang="scss" scoped>
.name {
  margin-top: -15px;
  color: red;
  font-size: 14px;
  margin-left: 20px;
}

.p {
  margin-top: 15px;
  color: gray;
}

:deep(.el-step__main) {
  text-align: center;
  margin-left: -0.8073rem;
}

.resg {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.el-form-item {
  margin-bottom: 18px;
}

.pTitle {
  margin-top: 0.2969rem;
  text-align: center;
  font-size: 0.0833rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}

:deep(.el-steps) {
  .is-process {
    color: #409eff;
    border-color: #409eff;
  }
}

.w-50 {
  width: 1.9271rem;
}
</style>
